import React from 'react';
import '../css/home.css';
// Importation d'une image
import profilePic from '../imgs/profile-pic.jpg'

function Home() {
  return (
    <div className="wrapper">
      <div className="about-container">
        <div className="profile-name-container">
          <img src={profilePic} alt="Profile pic" className='profile'/>
          <h3>Nicolas Carriere</h3>
          <h4 className="current">Currently Studying Computer Engineering Technology</h4>
          <h5 className="current">Graduate of Computer Programming</h5>
        </div>
        <div className="description-container">
          <h1>Let's me introduce myself</h1>
          <p className='description-about'>
            👋 Hi there! I'm Nicolas, a passionate computer programmer and proud Franco-Ontarian. Fluent in both French and English, I'm a bilingual tech enthusiast with a focus on programming, ready to embark on an exciting journey. 
          </p>
          <p className='description-about'>
          🎨 At just 21 years old, I've already embraced my uniqueness – I have ADHD, which I consider not a weakness but a strength. It fuels my creativity and innovative thinking, providing me with a constant flow of ideas for future projects.
          </p>
          <p className='description-about'>
          💻 My heart belongs to technology. I discovered this love at a young age while immersing myself in video games. The realization that I could create programs to entertain, educate, and assist others was a game-changer. I find immense joy in creating software, whether it's through a fun game or a useful application.
          </p>
          <div className='skills-container-main'>
            <h1>My Skills</h1>
            <div className="skills-container">
              <div className="frontend-skills-container">
              <div className="skillbar">
                  <div className="sexy-side html"></div>
                  <span className="title">HTML</span>
                  <span className="percent">HIGH-INTERMEDIATE</span>
                </div>
                <div className="skillbar">
                  <div className="sexy-side css"></div>
                  <span className="title">CSS</span>
                  <span className="percent">INTERMEDIATE</span>
                </div>
                <div className="skillbar">
                  <div className="sexy-side js"></div>
                  <span className="title">JS</span>
                  <span className="percent">INTERMEDIATE</span>
                </div>
              </div>
              <div className="backend-skills-container">
              <div className="skillbar">
                  <div className="sexy-side csharp"></div>
                  <span className="title">C#</span>
                  <span className="percent">HIGH-INTERMEDIATE</span>
                </div>
                <div className="skillbar">
                  <div className="sexy-side java"></div>
                  <span className="title">Java</span>
                  <span className="percent">HIGH-INTERMEDIATE</span>
                </div>
                <div className="skillbar">
                  <div className="sexy-side sql"></div>
                  <span className="title">SQL</span>
                  <span className="percent">HIGH-INTERMEDIATE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;