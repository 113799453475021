import React, { useState } from 'react';
import '../css/header.css';
import { Link } from 'react-router-dom';

function Header() {
  // La variable en utilisant le useState
  const [navbarLinksVisible, setNavbarLinksVisible] = useState(false);

  // Juste un toggle off et on
  function toggleNavButton() {
    setNavbarLinksVisible((prevState) => !prevState);
  }

  // Le div de navbar-links a un check pour si navbarLinksVisible est on ou pas 
  // et si on affiche la class ou pas.
  return (
    <nav className="navbar">
      <div className="brand-title"><Link to='/' className='text-title'>Nicolas Games</Link></div>
      <a className="toggle-navbutton" onClick={toggleNavButton}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </a>
      <div className={`navbar-links ${navbarLinksVisible ? 'active' : ''}`}>
        <ul>
          <li>
            <Link to='/' onClick={toggleNavButton}>Home</Link>
          </li>
          <li>
            <Link to='/projects' onClick={toggleNavButton}>Projects</Link>
          </li>
          <li>
            <Link to='/contact' onClick={toggleNavButton}>Contact</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
