import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/contact.css';

function Contact() {
  const navigate = useNavigate();

  const [info, setInfo] = useState({
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    subject: '',
    message: '',
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setInfo((previousState) => ({ ...previousState, [name]: value }));
  }

  function submit(event) {
    event.preventDefault();

    if (!info.subject || !info.message) {
      alert('Please fill in both the subject and message fields before submitting.');
    } else {
      const { subject, message } = info;

      const mailtoLink = `mailto:contact@nicocarr.ca?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(message)}`;

      window.location.href = mailtoLink;
      navigate('/');
    }
  }

  return (
    <div className="wrapper">
      <div className="contact-container">
        <h1>Contact Me</h1>
        <form onSubmit={submit}>
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              onChange={handleChange}
              type="text"
              id="subject"
              name="subject"
              value={info.subject}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              onChange={handleChange}
              id="message"
              name="message"
              rows="5"
              value={info.message}
            ></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
