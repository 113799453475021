import React from 'react'
import '../css/ski.css';
// Importation d'une image
import SkiPic from '../imgs/skiWebsite.png'

function Ski() {
  return (
    <div className="wrapper">
      <div className="ski-container">
        <div className="image-container">
          <img src={SkiPic} alt="ski website pic" className='skipic'/>
        </div>
        <div className="description-ski-container">
        <a href="https://github.com/nicocarr04/website-ski-exemple" target="_blank" className='remove-linkbar'><h1 className='ski-name'>Ski website</h1></a>
        <h2 className='ski-name'>HTML, CSS</h2>
          <p>
              I have developed a ski website that aims to provide a comprehensive platform for ski enthusiasts. I want to clarify that the project is not yet complete, and I don't intend to finish it. It serves as a testing ground for my web development learning journey.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Ski